import {
  useEffect,
  useRef,
  useCallback,
  createContext,
  useContext,
} from 'react';
import { useRouter } from 'next/router';

type NavContextType = {
  history: string[];
  push: (pathname: string, query?: Query) => void;
  back: () => void;
};

type Query = { [key: string]: string | number };

const NavContext = createContext<NavContextType>({
  back: () => {},
  history: [],
  push: () => {},
});

export const NavProvider = ({ children }: any) => {
  const router = useRouter();
  const historyReference = useRef<string[]>([]);

  useEffect(() => {
    // This event seems to fire off more than once for a single event. Only update the historyRef.current if its a new url.
    const handleRouteChange = (url: string) => {
      const parsedUrl = url.split('?')[0];
      if (
        historyReference.current.length === 0 ||
        historyReference.current[historyReference.current.length - 1] !==
          parsedUrl
      ) {
        historyReference.current = [...historyReference.current, parsedUrl];
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  const back = useCallback(() => {
    if (historyReference.current.length <= 1) {
      router.push('/card');
      return;
    }

    const newHistory = historyReference.current.slice(0, -1);
    const previousUrl = newHistory[newHistory.length - 1] || '/card';

    router.push(previousUrl, undefined, { shallow: true }).then(() => {
      historyReference.current = newHistory;
    });
  }, [router]);

  return (
    <NavContext.Provider
      value={{
        back: back,
        history: historyReference.current,
        push: router.push,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

// TODO change to useNavWithHistory?
export const useNavWithTokens = () => useContext(NavContext);

import { useEffect, useState } from 'react';
// eslint-disable-next-line unicorn/prefer-node-protocol
import { encode } from 'querystring';

import { useAuthContext } from 'apps/embedded-cbc/contexts/auth';
import { OnboardingState } from 'apps/embedded-cbc/contexts/auth/store';
import { useRouter } from 'next/router';

// Array of states that are valid after onboarding
export const COMPLETED_ONBOARDING_STATES: OnboardingState[] = [
  'ACCOUNT_LINKED',
  'CARD_ISSUED',
  'COMPLETE',
];

/**
 * Evaluates the page based on the onboarding state.
 * @param {OnboardingState | undefined} onboardingState - The onboarding state.
 * @returns {string} - Returns the page path.
 */
export const evaluateInitialPage = (
  onboardingState: OnboardingState | undefined,
) => {
  if (onboardingState === 'WELCOME') {
    return '/onboarding/step1';
  }

  if (onboardingState === 'CUSTOMER_CREATED') {
    return '/onboarding/step2';
  }

  if ('APPLICATION_CREATED' === onboardingState) {
    return '/onboarding/step3';
  }

  if (onboardingState === 'KYC_PASSED') {
    return '/onboarding/application-status';
  }

  if (
    !!onboardingState &&
    ['APPLICATION_CREATED', 'APPLICATION_SUBMITTED'].includes(onboardingState)
  ) {
    return '/onboarding/kyc-status';
  }

  if ('KYC_DOC_REQUIRED' === onboardingState) {
    return '/onboarding/verification';
  }

  if (onboardingState === 'APPLICATION_APPROVED') {
    return '/onboarding/approved';
  }

  if (onboardingState === 'KYC_FAILED') {
    return '/onboarding/declined';
  }

  if (onboardingState === 'KYC_UNDER_REVIEW') {
    return '/onboarding/pending';
  }

  if (onboardingState === 'CARD_ISSUED') {
    return '/onboarding/add-money';
  }

  if (onboardingState === 'ACCOUNT_LINKED') {
    return '/settings/deposit';
  }

  if (onboardingState === 'COMPLETE') {
    return '/card';
  }

  // Default to onboarding
  return '/onboarding/step1';
};

const ALLOWED_QUERY_PARAMS = new Set([
  '_gl',
  '_ga',
  'appInstanceId',
  'webview',
  'disable_cookies',
]);
const ALLOWED_QUERY_PREFIXES = ['_casl'];

/**
 * Custom hook getting the initial page on page load
 * @returns {Object} - Return isLoading and the pathname in object
 */
const useInitialPage = () => {
  const { user } = useAuthContext();
  const { query } = useRouter();

  const [isLoading, setIsLoading] = useState(true);
  const [pathName, setPathName] = useState<string>();

  useEffect(() => {
    if (isLoading && !!user?.onboarding_state) {
      const path = evaluateInitialPage(user?.onboarding_state);
      setIsLoading(false);

      const allowedQuery = Object.fromEntries(
        Object.entries(query).filter(
          ([key]) =>
            ALLOWED_QUERY_PARAMS.has(key) ||
            ALLOWED_QUERY_PREFIXES.some((prefix) => key.startsWith(prefix)),
        ),
      );

      const queryString = new URLSearchParams(encode(allowedQuery)).toString();

      if (queryString) {
        setPathName(`${path}?${queryString}`);
        return;
      }

      setPathName(path);
    }
  }, [isLoading, user]);

  return { isLoading, pathName };
};

export default useInitialPage;

import styled, { CSSObject, DefaultTheme } from 'styled-components';

const getBorderColor = (
  theme: DefaultTheme,
  isError?: boolean,
  isFocused?: boolean,
) => {
  if (isError) return theme.input.container.borderError;
  if (isFocused) return theme.input.container.borderFocused;

  return theme.input.container.borderDefault;
};

export const InputContainer = styled.div<{
  isError?: boolean;
  sx?: CSSObject;
  isFocused?: boolean;
}>(({ isError, isFocused, sx, theme }) => {
  return {
    backgroundColor: theme.input.container.backgroundColor,
    border: `1px solid ${getBorderColor(theme, isError, isFocused)}`,
    borderRadius: theme.input.container.borderRadius,
    fontFamily: theme.input.container.fontFamily,
    padding: theme.input.container.padding,
    ...sx,
  };
});

const Input = styled.input<{ hasLeftIcon?: boolean }>(
  ({ hasLeftIcon, theme }) => {
    const margin = hasLeftIcon ? '4px 12px 4px 4px' : '4px 12px 4px 12px';

    return {
      ':active': { outline: 'none' },
      ':focus': { outline: 'none' },

      '@media handheld (max-width: 425px)': {
        marginLeft: '-8px',
      },

      backgroundColor: theme.input.backgroundColor,
      border: 0,
      color: theme.input.color,
      fontSize: theme.input.fontSize,
      margin,
      padding: '0 0 8px',
      width: '100%',
    };
  },
);

const Label = styled.label`
  color: ${({ theme }) => theme.input.label.color};
  font-size: ${({ theme }) => theme.input.label.fontSize};
  line-height: ${({ theme }) => theme.input.label.lineHeight};
  padding: 12px;
`;

export const Text = styled.span``;

const exports = {
  Input,
  InputContainer,
  Label,
  Text,
};

export default exports;
